<template>
  <MainContainer :no-left-buttons="true">
    <template #leftPanelTop>
      <Help />
    </template>

    <template #rightPanel>
      <SideMenu />
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import SideMenu from '@/components/navbar/sideMenu.vue'
import MainContainer from '@/components/layout/mainContainer.vue'
import Help from '@/components/help/help.vue'

export default {
  name: 'SelfExclusionContainer',
  components: {
    MainContainer, SideMenu, Help
  },
  computed: {
    ...mapGetters(['userData'])
  }
}
</script>
