<template>
  <div
    :style="{ 'border': houdiniBorder ? 'none' : '', 'overflow': houdiniBorder ? '' : 'hidden' }"
    class="mx-auto d-flex cardPreviewFromPropsContainer cardPreviewFromPropsContainerQueries"
  >
    <slot />
    <div v-if="cancelled" class="cancelledCard" />
    <span v-if="cancelled" class="cancelled-text primary-colour text-center">CANCELLED<br> {{ new Date(dateCancelled).toLocaleDateString('en-GB') }}</span>
    <img
      :src="require('../../assets'+ picturePath)"
      style="height:100%;object-fit:cover;transition: 0.5s ease;border-radius: 10px"
    >
    <!-- initials -->
    <div
      v-if="!(firstInitial == '' && secondInitial == '')"
      class="playingCardWhiteBox left"
      :style="{'opacity': ((firstInitial != '' || secondInitial != '') && !cancelled ) ? '1' : '0.2'}"
    >
      <div
        :class="cancelled ? 'whiteBoxInnerCircleCancelled' : 'whiteBoxInnerCircle'"
        class=" p-3 p-sm-4"
        style="letter-spacing: 0px;margin-left:3px;"
      >
        <span 
          v-if="isMiniBasket"
          style="transform: translate(-2px, -1px);margin-left:3px;margin-top:3px;font-size:8px;"
        >
          {{ firstInitial }}{{ secondInitial }} 
        </span>
        <span 
          v-else
          style="transform: translate(-2px, -1px);"
        >
          {{ firstInitial }}{{ secondInitial }} 
        </span>
      </div>
    </div>
    <div
      v-if="number != 0"
      class="playingCardWhiteBox right"
      :style="{'opacity': number != 0 && !cancelled ? '1' : '0.2' }"
    >
      <div
        :class="cancelled ? 'whiteBoxInnerCircleCancelled' : 'whiteBoxInnerCircle'"
        class="p-3 p-sm-4"
      >
        <span 
          v-if="isMiniBasket"
          style="transform: translate(-2px, -1px);margin-top:7px;font-size:8px;"
        >
          {{ number }} 
        </span>
        <span 
          v-else
          style="transform: translate(-2px, -1px);"
        >
          {{ number }} 
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CardPreviewFromProps',
  props: {
    hero: {
      type: [Number],
      default: function () {
        return 0
      }
    },
    sport: {
      type: [Number],
      default: function () {
        return 0
      }
    },
    number: {
      type: [Number],
      default: function () {
        return ''
      }
    },
    firstInitial: {
      type: [String],
      default: function () {
        return ''
      }
    },
    secondInitial: {
      type: [String],
      default: function () {
        return ''
      }
    },
    colour: {
      type: [String],
      default: function () {
        return ''
      }
    },
    clubId: {
      type: [Number],
      default: function () {
        return 0
      }
    },
    cancelled: {
      type: Boolean,
      default: false
    },
    dateCancelled: {
      type: [String],
      default: null
    },
    houdiniBorder: {
      type: Boolean,
      default: false
    },
    isMiniBasket: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hover: false,
      isFirefox: false
    }
  },
  computed: {
    ...mapGetters(['mobileView']),
    picturePath () {
      // return `/heros/${this.hero}.png`
      try {
        if (this.sport !== 0) {
          return `/characterImages/${this.hero}.${this.readableColour}.${this.sport}.png`
        } else if (this.colour !== '#e1e1e1') {
          return `/characterImages/${this.hero}.${this.readableColour}.png`
        } else if (this.hero !== 0) {
          return `/characterImages/${this.hero}.png`
        }
        return '/0.png'
      } catch {
        return '/0.png'
      }
    },
    readableColour () {
      switch (this.colour) {
        case '#ff0000': // red
          return 1
        case '#0000ff': // blue
          return 2
        case '#ffff00': // yellow
          return 3
        case '#008000': // green
          return 4
        case '#ff9900': // orange
          return 5
        case '#800080': // purple
          return 6
        case '#fa42d2': // pink
          return 7
        case '#ffffff': // white
          return 8
        default :
          return 0
      }
    }
  },
  created () {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.isFirefox = true
    }
  }
}
</script>

<style>
.cardFontSize {
  font-size:8px !important;
}
.cancelledCard:after {
  z-index: 5;
  display:block;
  content:"";
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:#999;
  opacity:.7;
  height: 100%;
  width: 100%;
}
.cancelled-text {
  z-index: 6;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
