<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle
      :loading="loading"
      text="Transactions"
      icon="money-bill"
    />
    <div class="flex-grow-1" style="overflow: auto; position: relative">
      <div
        class="primary-colour mx-auto font-weight-light mt-2"
        style="width: 100%; background: var(--bg-color);margin-top:20px !important;font-size: 0.9rem;"
      >
        Your transctions for the past twelve months (any queries, please contact us quoting a transaction reference).
      </div>
      <div
        v-if="transactions.length > 0"
        class="transactionsTable mt-3"
        style="overflow-y: auto;"
      >
        <BTable
          id="transactions-table"
          class="text-left"
          style="overflow-y: auto;"
          head-variant="dark"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="transactions"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          responsive
          :striped="true"
          hover
          small
          sort-icon-left
          auto-width
        >
          <template v-slot:cell(dateadded)="data">
            <div style="margin-top:7px;">
              {{ new Date(data.item.DateAdded).toLocaleDateString([userLanguage]) }}  {{ new Date(data.item.DateAdded).toLocaleTimeString([userLanguage]).substring(0, 5) }}
            </div>
          </template>
          <template v-slot:cell(maskedpan)="data">
            <div style="width:50px;background-color:white;border-radius: 5px;padding-left:5px;padding-right:5px;float:left;">
              <img
                v-if="windowWidth > 576"
                :src="require('@/assets/' + data.item.PaymentTypeDescription + '.png')"
                style="width:40px;height:30px;"
              >
            </div>
            <div v-if="windowWidth > 576" style="margin-left:10px;float:left;margin-top:7px;">
              {{ data.item.MaskedPan }}
            </div>
            <div v-else style="margin-top:7px;">
              {{ data.item.MaskedPan }}
            </div>
          </template>
          <template v-slot:cell(amount)="data">
            <div
              v-if="data.item.Amount < 0"
              style="margin-top:7px;color:var(--pr-color) !important;cursor: pointer;"
              title="payout (this could relate to the likes of a refund or a leaderboard prize etc.)"
            >
              £{{ data.item.Amount.toFixed(0) }}
            </div>
            <div v-else style="margin-top:7px;">
              £{{ data.item.Amount.toFixed(0) }}
            </div>
          </template>
          <template v-slot:cell(transactionreference)="data">
            <div class="text-nowrap" style="margin-top:7px;">
              {{ data.item.TransactionReference }}
              <div v-if="mobileView">
                <br>For Draws This Month:<br><br>
                <div v-if="data.item.TransactionDraws.length > 0">
                  <div v-for="(draw, i) in data.item.TransactionDraws" :key="i">
                    {{ new Date(draw).toLocaleDateString([userLanguage]) }}
                  </div>
                </div>
                <div v-else>
                  N/A
                </div>
                <br>
              </div>
            </div>
          </template>
          <template #cell(Draws)="row">
            <mdbBtn
              style="width:140px;margin:0 !important;font-size:0.8rem !important;"
              class="btn primary-btn btn-radius btn-sm btn-md"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? 'Hide' : 'Show' }} DRAWS
            </mdbBtn>
          </template>
          <template #row-details="data">
            For Draws This Month:<br><br>
            <div v-if="data.item.TransactionDraws.length > 0">
              <div v-for="(draw, i) in data.item.TransactionDraws" :key="i">
                {{ new Date(draw).toLocaleDateString([userLanguage]) }}
              </div>
            </div>
            <div v-else>
              N/A
            </div>
            <br>
          </template>
        </BTable>
        <div
          v-if="transactions.length > perPage"
          class="p-1 mt-2"
          style="width:100%; background: #fff; border-radius: 5px;"
        >
          <b-pagination
            v-show="transactions.length > perPage"
            v-model="currentPage"
            class="ml-auto"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            aria-controls="transactions-table"
            prev-text="<"
            next-text=">"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'

export default {
  name: 'Transactions',
  components: {
    LeftTopPanelTitle
  },
  data () {
    return {
      currentPage: 1,
      transactions: [],
      sortBy: '',
      sortDesc: false,
      perPage: 20,
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'DateAdded', label: 'Transaction Date', sortable: true },
          { key: 'Amount', label: 'Amount Paid', sortable: true },
          { key: 'MaskedPan', label: 'Card Details' },
          { key: 'TransactionReference', label: 'Reference' },
          { key: 'Draws', label: 'Draws This Month', sortable: true }
        ]
      } else {
        return [
          { key: 'DateAdded', label: 'Date', sortable: true, tdClass: 'col30' },
          { key: 'Amount', label: '£', sortable: true, tdClass: 'col20' },
          { key: 'MaskedPan', label: 'Card', tdClass: 'col20' },
          { key: 'TransactionReference', label: 'Ref.', tdClass: 'col30' },
        ]
      }
    },
    rows () {
      try {
        return this.transactions.length
      } catch { return 0 }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  created () {
    this.getPlayerTransactions()
  },
  methods: {
    getPlayerTransactions () {
      this.loading = true
      userService.getPlayerTransactions(this.userData.access_token, this.userData.une).then((res) => {
        this.transactions = [...res.data]
        setTimeout(() => {
          this.loading = false
        }, 700)
      }).catch(() => {})
    },
    onResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss">
.pagination button[aria-checked=true] {
  background: var(--pr-color) !important;
}
.transactionsTable * {
  font-size: 0.85rem !important;
  color: var(--lt-color);
}
</style>
