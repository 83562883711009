<template>
  <div
    v-if="heroes.length > 0"
  >
    <div
      v-show="show && heroes.length > 0"
      style="position: relative;"
      class="d-flex align-items-center justify-self-center justify-content-center resultHeroCardToggleHeight mx-auto"
    >
      <mdb-icon
        icon="fas fa-chevron-left fa-2x primary-colour"
        class="mr-2"
        size="lg"
        style="cursor:pointer;width:20px;"
        @click.native="decViewingHero()"
      />
      <CardPreviewFromProps
        class="my-0 heroCardToggleWidth mx-auto"
        style="margin-top:10px !important;"
        :hero="selectedHero.Hero"
        :colour="selectedHero.Colour"
        :sport="selectedHero.Sport"
        :number="selectedHero.Number"
        :first-initial="selectedHero.FirstInitial"
        :second-initial="selectedHero.SecondInitial"
        :cancelled="selectedHero.Cancelled"
        :date-cancelled="selectedHero.DateCancelled"
      />
      <ReceiptPopover
        style="position: absolute; top: -5px; right: 10px;"
        :hero="selectedHero" 
      />
      <mdb-icon
        icon="fas fa-chevron-right fa-2x primary-colour"
        class="ml-2"
        size="lg"
        style="cursor:pointer;width:20px;"
        @click.native="incViewingHero()"
      />
    </div>
    <div
      v-if="show && heroes.length === 0 && !loading"
      class="d-flex flex-column align-items-center justify-content-center p-3 mx-auto my-3 cp"
      style="width: 150px; border: var(--pr-color) 2px solid"
      @click="$router.push({ path: '/play', query: { page: 1 } })"
    >
      <span class="text-center primary-colour">ADD A HERO TO ENTER THE DRAW</span>
      <i class="fa fa-plus primary-colour p-2 mt-2" style="border-radius:50%; border: solid 3px;" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'
import CardPreviewFromProps from '@/components/cardPreviews/cardPreviewFromProps.vue'
import ReceiptPopover from './receiptPopover.vue'

export default {
  name: 'HeroCardToggle',
  components: { CardPreviewFromProps, ReceiptPopover },
  data () {
    return {
      heroes: [],
      loading: false,
      selectedHeroIndex: 0,
      show: true
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    selectedHero () {
      if (this.heroes.length === 0) { return {} }
      return this.heroes[this.selectedHeroIndex]
    },
    hId () {
      try {
        return Number(this.$route.query.hid)
      } catch { return 0 }
    }
  },
  watch: {
    selectedHero () {
      this.$emit('selectedHero', this.selectedHero)
    }
  },
  mounted () {
    this.getMyHeroes()
  },
  methods: {
    decViewingHero () {
      this.show = false
      if (this.selectedHeroIndex === 0) {
        this.selectedHeroIndex = this.heroes.length - 1
      } else { this.selectedHeroIndex = this.selectedHeroIndex - 1 }
      this.show = true
    },
    incViewingHero () {
      this.show = false
      if (this.selectedHeroIndex === this.heroes.length - 1) {
        this.selectedHeroIndex = 0
      } else { this.selectedHeroIndex = this.selectedHeroIndex + 1 }
      this.show = true
    },
    async getMyHeroes () {
      this.loading = true
      try {
        const showOnlyActive = false
        const res = await userService.getMyHeroes(showOnlyActive, this.userData.access_token)
        this.heroes = res.data
        const index = this.heroes.findIndex(h => h.BasketOrdersId === this.hId)
        if (index != -1) {
          this.selectedHeroIndex = index
          this.$router.push('/results')
        }
      } catch {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      this.loading = false
    }
  }
}
</script>

<style>
.resultHeroCardToggleHeight {
  height: 400px;
  max-height: 50vh;
}
@media (max-width: 768px) {
  .resultHeroCardToggleHeight {
    height: 250px;
  }
}
.heroCardToggleWidth {
  width: 200px;
} 
@media (max-width: 576px) {
  .heroCardToggleWidth {
    width: 150px;
  }
}

</style>
