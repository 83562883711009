<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle text="Help" icon="info-circle" />
    <div
      style="width:100%;color: var(--pr-color);font-size: 0.9rem;"
      class="px-3 mt-3"
    >
      <div class="mt-3">
        <h2>FAQ</h2>
        Coming soon.
      </div>
      <div class="mt-3">
        <h2>Contact Us</h2>
        For any support queries, please contact us at <a href="mailto:support@clubsupporter.co.uk"><span style="color:white;text-decoration:none;">support@clubsupporter.co.uk</span></a>. We aim to respond within 1-2 business days (although most requests are usually addressed within 8 business hours). 
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'

export default {
  name: 'Help',
  components: {
    LeftTopPanelTitle
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'mobileView'
    ])
  },
  methods: {
  }
}
</script>
